import React, { Fragment } from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import data from "../data/legal-notice-data"
import TitleBlock from "../components/TitleBlock"
import parse from "react-html-parser"

const LegalNotice = () => {
  const path = [{ label: "Inicio", to: "/" }, { label: "Aviso legal" }]

  const renderContent = () => {
    if (!data || !data.length) return

    return data.map((item, index) => {
      return (
        <Fragment key={index}>
          <TitleBlock
            title={item.title}
            className="LegalNotice-subtitle"
            icon=""
          />
          <div className="LegalNotice-text">{parse(item.content)}</div>
        </Fragment>
      )
    })
  }

  return (
    <div className="LegalNotice">
      <Layout>
        <Hero image="/imgs/servicios-actividades-ret.jpg" text="Aviso legal" />
        <div className="container950">
          <Breadcrumb path={path} className="Assosiation-breadcrumb" />
          <TitleBlock
            title="Aviso legal"
            className="LegalNotice-title"
            icon="fas fa-pen-square"
          />
          <div className="LegalNotice-text">
            <p style={{ color: "red", fontWeight: "bold" }}>¡¡ATENCIÓN!!</p>
            <p>
              En ningún caso EFPA España utiliza correos electrónicos en las
              comunicaciones por email, que no sean del dominio efpa.es. Si
              reciben un email de ese tipo no lo abran ni hagan clic con el
              ratón a ningún enlace, denuncien la suplantación en el propio
              email y bórrenlo directamente.
              <a
                href="https://www.incibe.es/ciudadania/blog/email-spoofing-cuando-el-correo-parece-haber-sido-enviado-por-mi-o-alguien-conocido"
                target="_blank"
              >
                ¿Cómo proceder ante correos sospechosos?
              </a>
            </p>
          </div>
          {renderContent()}
        </div>
      </Layout>
    </div>
  )
}

export default LegalNotice
